var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"no-print"},[_c('v-app-bar',{attrs:{"fixed":"","fade-on-scroll":"","elevation":"1","dense":"","color":"white","app":"","clipped-left":""}},[_c('v-toolbar-title',{staticClass:"text-overline",staticStyle:{"font-size":"16px!important"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'front' }}},[_c('v-img',{staticClass:"mr-1 float-left",attrs:{"alt":"LibertyDNS","src":require("@/assets/logo/libertydns/LibertyDNS_light_198x48.png"),"width":"148","height":"36","fit":"inside"}})],1)],1),_c('v-progress-linear',{attrs:{"active":_vm.isLoading,"indeterminate":_vm.isLoading,"absolute":"","bottom":"","color":"purple"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"purple darken-2"},on:{"click":_vm.search}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'search'],"fixed-width":""}})],1),_c('v-menu',{attrs:{"offset-y":"","open-on-click":"","open-on-hover":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"purple darken-2"}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":['fas','question'],"fixed-width":""}})],1)]}}]),model:{value:(_vm.helpMenu),callback:function ($$v) {_vm.helpMenu=$$v},expression:"helpMenu"}},[_c('v-card',[_c('v-card-text',[_vm._v(" Questions or comments? "),_c('a',{attrs:{"href":"https://liberty.io/contact/","target":"_blank"}},[_vm._v("Contact us!")])])],1)],1),(!_vm.isAuthenticated)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_c('v-tabs',{attrs:{"slider-color":"purple","right":""}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"to":{ name: 'front' }}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xsOnly),expression:"$vuetify.breakpoint.xsOnly"}]},[_c('v-menu',{attrs:{"offset-y":"","open-on-click":"","open-on-hover":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{staticClass:"purple--text",attrs:{"right":"","icon":['fas', 'bars'],"fixed-width":""}})],1)]}}],null,false,365822566)},[_c('v-tabs',{attrs:{"vertical":"","slider-color":"purple"}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"to":{ name: 'front' }}})],1)],1)],1)]):_vm._e(),(_vm.isAuthenticated)?_c('v-menu',{attrs:{"offset-y":"","open-on-click":"","open-on-hover":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"purple--text text--darken-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('Avatar',{attrs:{"attr":_vm.mainProfile,"size":36}})],1)]}}],null,false,3386175652)},[_c('v-list',{staticClass:"ma-0 pa-0"},[_c('v-list-item',{attrs:{"to":{ name: 'profile' }}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"purple--text text--darken-2",attrs:{"icon":['fas', 'user'],"fixed-width":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Profile ")])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'user-account-list' }}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"purple--text text--darken-2",attrs:{"icon":['fas', 'folder'],"fixed-width":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Accounts ")])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"purple--text text--darken-2",attrs:{"icon":['fas', 'chart-line'],"fixed-width":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Dashboard ")])],1)],1),(_vm.isPermitServiceAdmin)?_c('v-list-item',{attrs:{"to":{ name: 'service-admin' }}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"purple--text text--darken-2",attrs:{"icon":['fas', 'id-badge'],"fixed-width":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Service Admin ")])],1)],1):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.onLogout}},[_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"purple--text text--darken-2",attrs:{"icon":['fas', 'shield-alt'],"fixed-width":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Logout ")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }