import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/domain',
            name: 'domain-redirect-to-search',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "domain" */ '../views/Domain.vue'),
        },
        {
            path: '/domain/:domain',
            name: 'domain-redirect',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "domain" */ '../views/Domain.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/profile/authn/loginfront',
            name: 'profile-settings-authn-loginfront',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/Session.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/Index.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/Setup.vue'),
        },
        {
            path: '/service/settings',
            name: 'service-admin-settings',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-settings" */ '../views/service/Settings.vue'),
        },
        // {
        //     path: '/service/c2',
        //     name: 'service-admin-c2',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-c2" */ '../views/service/C2.vue'),
        // },
        {
            path: '/service/dns',
            name: 'service-admin-dns',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-dns" */ '../views/service/Dns.vue'),
        },
        {
            path: '/service/dynamic-shared-domain',
            name: 'service-admin-dynamic-shared-domain',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-dynamic-shared-domain" */ '../views/service/DynamicSharedDomain.vue'),
        },
        {
            path: '/user/account-list',
            name: 'user-account-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-account-list" */ '../views/user/AccountList.vue'),
        },
        {
            path: '/user/create/account',
            name: 'user-create-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-create-account" */ '../views/user/CreateAccount.vue'),
        },
        {
            path: '/search',
            name: 'search',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        },
        {
            path: '/account/:accountId',
            name: 'account-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-dashboard" */ '../views/account/Dashboard.vue'),
        },
        {
            path: '/account/:accountId/delete',
            name: 'account-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete" */ '../views/account/Delete.vue'),
        },
        {
            path: '/account/:accountId/create/client-token',
            name: 'account-create-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-client-token" */ '../views/account/CreateClientToken.vue'),
        },
        {
            path: '/account/:accountId/create/domain',
            name: 'account-create-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-domain" */ '../views/account/CreateDomain.vue'),
        },
        {
            path: '/account/:accountId/create/domain/register',
            name: 'account-create-domain-register',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-domain-register" */ '../views/account/CreateDomainRegister.vue'),
        },
        {
            path: '/account/:accountId/create/domain/verify',
            name: 'account-create-domain-verify',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-domain-verify" */ '../views/account/CreateDomainVerify.vue'),
        },
        {
            path: '/account/:accountId/search/client-token',
            name: 'account-search-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-client-token" */ '../views/account/SearchClientToken.vue'),
        },
        {
            path: '/account/:accountId/search/domain',
            name: 'account-search-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-domain" */ '../views/account/SearchDomain.vue'),
        },
        {
            path: '/account/:accountId/search/domain-dispute',
            name: 'account-search-domain-dispute',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-domain-dispute" */ '../views/account/SearchDomainDispute.vue'),
        },
        {
            path: '/account/:accountId/search/dynamic-dns-record',
            name: 'account-search-dynamic-dns-record',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-dynamic-dns-record" */ '../views/account/SearchDynamicDnsRecord.vue'),
        },
        {
            path: '/account/:accountId/search/user',
            name: 'account-search-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-user" */ '../views/account/SearchUser.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain',
            name: 'account-view-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-domain" */ '../views/account/ViewDomain.vue'),
        },
        {
            path: '/account/:accountId/nameservers',
            name: 'account-view-nameservers',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-nameservers" */ '../views/account/ViewNameservers.vue'),
        },
        {
            path: '/account/:accountId/whois',
            name: 'account-view-whois',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-whois" */ '../views/account/ViewWhois.vue'),
        },
        {
            path: '/account/:accountId/user/:userId',
            name: 'account-view-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-user" */ '../views/account/ViewUser.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain/nameservers',
            name: 'account-edit-domain-nameservers',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-domain-nameservers" */ '../views/account/EditDomainNameservers.vue'),
        },
        {
            path: '/account/:accountId/verify/domain/:domain',
            name: 'account-verify-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-verify-domain" */ '../views/account/VerifyDomain.vue'),
        },
        {
            path: '/account/:accountId/dispute/domain/:domain',
            name: 'account-dispute-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-dispute-domain" */ '../views/account/DisputeDomain.vue'),
        },
        // {
        //     path: '/account/:accountId/domain/:domain/website-list',
        //     name: 'account-view-domain-website-list',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-view-domain-website-list" */ '../views/account/ViewDomainWebsiteList.vue'),
        // },
        {
            path: '/account/:accountId/domain/:domain/delete',
            name: 'account-delete-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete-domain" */ '../views/account/DeleteDomain.vue'),
        },
        {
            path: '/account/:accountId/delete/dynamic-dns-record',
            name: 'account-delete-dynamic-dns-record',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete-dynamic-dns-record" */ '../views/account/DeleteDynamicDnsRecord.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain/dns',
            name: 'account-edit-dns',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-dns" */ '../views/account/EditDns.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain/transfer',
            name: 'account-transfer-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-transfer-domain" */ '../views/account/TransferDomain.vue'),
        },
        {
            path: '/webauthz/grant',
            name: 'webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
